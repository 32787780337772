import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import { Typography } from '@mui/material';
import { options } from '../utils/richTextOptions';
import loadable from '@loadable/component';
import TOC from '../components/TOC';
import styled from '@emotion/styled';
import { CalculatorTabs } from '../components/CalculatorTabs';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
const Sdiv = styled.div`
  min-height: 400px;
`;

const Loader = () => (
  <Sdiv>
    <p>Laster kalkulator ... </p>
  </Sdiv>
);

const Calculator = ({ id }) => {
  if (id === 'Kalorikalkulator') {
    const Kalorikalkulator = loadable(() =>
      import('../components/Kalorikalkulator/Kalorikalkulator'),
    );
    return <Kalorikalkulator />;
  }
  if (id === 'BMR') {
    const Kalorikalkulator = loadable(() =>
      import('../components/Kalorikalkulator/Kalorikalkulator'),
    );
    return <Kalorikalkulator bmr />;
  }
  if (id === 'BMI') {
    const BMI = loadable(() => import('../components/BMI/BMI'));
    return <BMI />;
  }
  return <></>;
};
const STypography = styled(Typography)`
  margin-top: var(--medium);
`;
const CalculatorPage = ({ data }) => {
  const {
    metaDescription,
    metaTitle,
    pageTitle,
    richTextContent,
    calculatorIdentifier,
  } = data.contentfulCalculatorPage;
  return (
    <Layout>
      <SEO metaDescription={metaDescription} title={metaTitle} />
      <STypography variant="h4" component="h1">
        {pageTitle}
      </STypography>
      <CalculatorTabs />
      <Calculator id={calculatorIdentifier} fallback={<Loader />} />
      <TOC />
      {renderRichText(richTextContent, options)}
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    contentfulCalculatorPage(slug: { eq: $slug }) {
      __typename
      contentful_id
      metaTitle
      metaDescription
      pageTitle
      richTextContent {
        raw
        references {
          __typename
          ... on ContentfulPage {
            id
            slug
            contentful_id
          }
          ... on ContentfulReviewPage {
            id
            slug
            contentful_id
          }
          ... on ContentfulCalculatorPage {
            id
            slug
            contentful_id
          }
          ... on ContentfulImage {
            url {
              url
            }
            __typename
            contentful_id
            altTag
            image {
              gatsbyImageData(width: 1600)
            }
          }
        }
      }

      calculatorIdentifier
    }
  }
`;

export default CalculatorPage;
